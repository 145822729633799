import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class InstagramService {


  constructor(private  http:  HttpClient) {
  }

  getAutocompleteUsersList(query){
    if(!query){
      return new Observable(observer => {
        observer.next({users:[]})
      })
    }
    return this.http.get<any>('https://www.instagram.com/web/search/topsearch/?context=blended&query=' + encodeURIComponent(query));
  }

}
